import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#2e4fa7',
                secondary: '#e9f6f9',
            }
        }
        
    }
});
