<template>
    <v-app>
        
        <v-main>
            <v-btn
                absolute
                top
                right
                text
                color="primary"
                outlined
                @click="closeApplication"
                class="d-none d-md-flex"
            >
                Close Application
            </v-btn>
            <v-fab-transition>
                
                <v-btn
                    fab
                    color="primary"
                    dark
                    absolute
                    top
                    right
                    small
                    class="d-flex d-sm-none"
                    style="z-index:3; position:fixed"
                    @click="closeApplication"
                >
                    <v-icon
                        small
                    >mdi-close</v-icon>
                </v-btn>
            </v-fab-transition>            
            <v-row class="mt-4">
                <v-col
                    align="center"
                    justify="center"
                >
                    <v-card            
                        width="700"
                        flat
                    >  
                        
                        <v-img
                            :src="require('../assets/logo.png')"
                            contain
                            height="70"
                        />
                        <h1 class="primary--text  font-weight-bold">
                            St. Augustine Girls' High School
                        </h1>
                        <div class="text-h6 font-weight-light primary--text">
                            Evans Street, Curepe, Trinidad
                        </div>
                        <div class="mt-4 primary--text font-weight-bold text-h5">
                            APPLICATION FORM FOR ENTRY INTO FORM SIX (6) 2022
                        </div>
                        
                    </v-card>
                    <v-card            
                        width="550"
                        flat
                    >
                        <v-alert
                                
                            type="error"
                            class="mt-4"

                            text
                            outlined
                        >
                            Please 
                            <v-btn 
                                class="mx-3" 
                                color="red" 
                                dark
                                @click="showInstructions"
                            >
                                Click to Read
                            </v-btn>
                            instructions before proceeding.
                        </v-alert>
                    </v-card> 
                </v-col>
            </v-row>
            <v-row class="">
                <v-col
                    align="center"
                    justify="center"
                >
                    <v-card width="550" flat>
                        <v-alert
                            border="left"
                            colored-border
                            type="info"
                            class="mt-4 text-justify"
                            color="primary"
                            text
                            outlined
                        >
                            Application ID# <strong>{{ recordApplication.application_id }}</strong>, please take note of your application ID
                            as it is needed to check / change any of your application details.
                        </v-alert>
                    </v-card>
                    <v-card            
                        width="550"
                        class="mt-10"
                    >  
                        <v-card-title class="secondary--text pl-4 primary">
                            STUDENT INFORMATION
                        </v-card-title>
                        
                    </v-card>
                </v-col>
            </v-row>

            <v-row
                align="center"
                justify="center"
            >
                <v-card
                    class="pa-6 mt-5" 
                    width="550"
                    ref="form"
                >
                    <v-row 
                        align="center"
                        justify="center"
                        v-for="(field, index) in fields"
                        :key="index"
                    >
                        <v-col
                            cols="12"                       
                        >
                            <date-picker
                                v-if="field.type == 'date'"
                                v-bind:label="field.label"
                                v-bind:disabled="field.disabled"
                                v-bind:date="recordApplication[field.model]"
                                v-on:change-date="updateDate"
                                v-bind:saveStatus="field.saveStatus"
                            ></date-picker>

                            <v-text-field
                                v-else-if="field.type == 'email'"
                                v-bind:label="field.label"
                                v-model="recordApplication[field.model]"
                                @blur="validate(field, 'application')"
                                :prepend-icon="field.icon"                            
                                autocomplete="false"
                                :error-messages="field.errorMessages"
                                :error="field.error"
                                counter
                                :maxlength="field.maxLength" 
                                :ref="field.ref"
                                hide-details="auto"
                                :disabled="field.disabled"
                                :filled="field.filled" 
                            >
                                <template v-slot:append>
                                    <v-fade-transition>
                                        <v-progress-circular
                                            v-if="field.saveStatus.saving"
                                            size="16"
                                            width="3"
                                            color="info"
                                            indeterminate
                                        ></v-progress-circular>
                                    </v-fade-transition>

                                    <v-fade-transition>    
                                        <v-icon
                                            v-if="field.saveStatus.saveSuccess"
                                            small
                                            color="info"                                        
                                        >
                                            mdi-check-all
                                        </v-icon>
                                    </v-fade-transition>

                                    <v-fade-transition>    
                                        <v-icon
                                            v-if="field.saveStatus.saveError"
                                            small
                                            color="red"                                        
                                        >
                                            mdi-alert-circle
                                        </v-icon>
                                    </v-fade-transition>
                                </template>
                            </v-text-field>

                            <v-text-field
                                v-else-if="field.type == 'phone'"
                                v-bind:label="field.label"
                                v-model="recordApplication[field.model]"
                                @blur="validate(field, 'application')"
                                @keypress="numberOnly"
                                :prepend-icon="field.icon"
                                placeholder=" "
                                hint="Enter 7 digits only"
                                persistent-hint
                                prefix="1868"
                                maxlength="7"
                                autocomplete="none"
                                :error-messages="field.errorMessages"
                                :error="field.error"
                                :ref='field.ref'
                                hide-details="auto"
                                :disabled="field.disabled"
                                :filled="field.filled" 
                            >
                                 <template v-slot:append>
                                    <v-fade-transition>
                                        <v-progress-circular
                                            v-if="field.saveStatus.saving"
                                            size="16"
                                            width="3"
                                            color="info"
                                            indeterminate
                                        ></v-progress-circular>
                                    </v-fade-transition>

                                    <v-fade-transition>    
                                        <v-icon
                                            v-if="field.saveStatus.saveSuccess"
                                            small
                                            color="info"                                        
                                        >
                                            mdi-check-all
                                        </v-icon>
                                    </v-fade-transition>

                                    <v-fade-transition>    
                                        <v-icon
                                            v-if="field.saveStatus.saveError"
                                            small
                                            color="red"                                        
                                        >
                                            mdi-alert-circle
                                        </v-icon>
                                    </v-fade-transition>
                                </template>
                            </v-text-field>

                            <v-text-field
                                v-else
                                v-bind:label="field.label"
                                v-model="recordApplication[field.model]"
                                @blur="validate(field, 'application')"
                                :prepend-icon="field.icon"                            
                                autocomplete="none"
                                :counter="field.counter"
                                :maxlength="field.maxlength" 
                                :disabled="field.disabled"
                                :filled="field.filled"
                                :ref="field.ref"
                                :error-messages="field.errorMessages"
                                :error="field.error"
                                hide-details="auto"       
                            >
                                <template v-slot:append>
                                    <v-fade-transition>
                                        <v-progress-circular
                                            v-if="field.saveStatus.saving"
                                            size="16"
                                            width="3"
                                            color="info"
                                            indeterminate
                                        ></v-progress-circular>
                                    </v-fade-transition>

                                    <v-fade-transition>    
                                        <v-icon
                                            v-if="field.saveStatus.saveSuccess"
                                            small
                                            color="info"                                        
                                        >
                                            mdi-check-all
                                        </v-icon>
                                    </v-fade-transition>

                                    <v-fade-transition>    
                                        <v-icon
                                            v-if="field.saveStatus.saveError"
                                            small
                                            color="red"                                        
                                        >
                                            mdi-alert-circle
                                        </v-icon>
                                    </v-fade-transition>
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-row>     
                </v-card>
            </v-row>

            <v-row class="mt-4">
                <v-col
                    align="center"
                    justify="center"
                >
                    <v-card            
                        width="550"
                    >  
                        <v-card-title class="secondary--text pl-4 primary">
                            <div>CSEC SUBJECTS</div>
                        </v-card-title>

                        <v-card-text>
                            <v-alert
                                border="left"
                                colored-border
                                type="info"
                                class="mt-4 text-justify"
                                color="primary"
                                text
                                outlined
                            >
                                Subjects done at CSEC Level in the order given on the results slip
                            </v-alert>

                            <v-data-table
                                :headers="headers"
                                :items="subjects"
                                hide-default-footer
                                hide-default-header
                            >
                                <template v-slot:header="{ props: { headers } }">
                                    <thead>
                                        <tr>
                                            <th style="width:280px">
                                                <div class="text-h6">
                                                    {{ headers[0].text }}
                                                </div>
                                                <div class="text-caption">
                                                    {{ headers[0].subtext }}
                                                </div>
                                            </th>
                                            <th>
                                                <div class="text-h6">
                                                    {{ headers[1].text }}
                                                </div>
                                                <div class="text-caption">
                                                    {{ headers[1].subtext }}
                                                </div>
                                            </th>
                                            <th>
                                                <div class="text-h6">
                                                    {{ headers[2].text }}
                                                </div>
                                                <div class="text-caption">
                                                    {{ headers[2].subtext }}
                                                </div>
                                            </th>
                                            <th
                                                style="width:30"
                                            ></th>
                                        </tr>
                                    </thead>
                                </template>

                                <template v-slot:item="props">
                                    <tr>
                                        <td>
                                            <v-autocomplete
                                                v-model="props.item.id" 
                                                outlined
                                                dense
                                                hide-details
                                                placeholder="Subject"
                                                :items="csecSubjects"
                                                item-text="title"
                                                item-value="id"
                                                @change="updateCsecGrade(props.index)"
                                                :disabled="props.item.disabled"                                             
                                            ></v-autocomplete>
                                        </td>
                                        <td>
                                            <v-select
                                                v-model="props.item.grade" 
                                                :items="grades"
                                                outlined
                                                dense
                                                hide-details
                                                @change="updateCsecGrade(props.index)"
                                                :disabled="props.item.disabled"  
                                            ></v-select>
                                        </td>
                                        <td>
                                            <v-text-field
                                                v-model="props.item.profiles"
                                                outlined
                                                dense
                                                hide-details
                                                class="font-weight-bold"
                                                @change="updateCsecGrade(props.index)" 
                                                :disabled="props.item.disabled" 
                                            ></v-text-field>
                                        </td>
                                        <td>
                                            <v-fade-transition>
                                                <v-progress-circular
                                                    v-if="props.item.saveStatus.saving"
                                                    size="16"
                                                    width="3"
                                                    color="info"
                                                    indeterminate
                                                ></v-progress-circular>
                                            </v-fade-transition>

                                            <v-fade-transition>    
                                                <v-icon
                                                    v-if="props.item.saveStatus.saveSuccess"
                                                    small
                                                    color="info"                                        
                                                >
                                                    mdi-check-all
                                                </v-icon>
                                            </v-fade-transition>

                                            <v-fade-transition>    
                                                <v-icon
                                                    v-if="props.item.saveStatus.saveError"
                                                    small
                                                    color="red"                                        
                                                >
                                                    mdi-alert-circle
                                                </v-icon>
                                            </v-fade-transition>  
                                                
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-card-text>
                        
                    </v-card>
                </v-col>
            </v-row>

            <v-row class="mt-4">
                <v-col
                    align="center"
                    justify="center"
                >
                    <v-card            
                        width="550"
                    >  
                        <v-card-title class="secondary--text pl-4 primary">
                            <div>CHOICE OF ADVANCED LEVEL SUBJECT</div> 
                            
                        </v-card-title>
                        <v-card-text>
                            <v-alert
                                border="left"
                                colored-border
                                type="info"
                                class="mt-4 text-justify"
                                color="primary"
                                text
                                outlined
                            >
                                Choose <strong>three</strong> subjects from the four lines below.
                                Only <strong>One</strong> Subject can be chosen from EACH line.
                                You can only select one subject from any three rows.
                                Computer Science & Information Technology is counted as two separate Subjects.
                                Students choosing this option may select the <strong>THIRD</strong> option
                                from any other subject group.
                                You have two options please select your choices.
                            </v-alert>
                            
                        </v-card-text>

                        <v-card-text>
                            <v-alert
                                text
                                color="primary"
                                dense
                                border="left"
                                colored-border
                            >
                                OPTION 1
                            </v-alert>
                            <v-data-table
                                :headers="headersSubjectChoice1"
                                :items="subjectChoices1"
                                hide-default-footer
                                hide-default-header
                            >
                                <template v-slot:item="{ item }">
                                    <tr>
                                        <td>
                                            {{ item.line }}
                                        </td>
                                        <td>
                                            <v-select
                                                v-model="subjectsChoice1[item.line]"
                                                outlined
                                                dense
                                                hide-details
                                                placeholder="Subject"
                                                :items="item.subjects"
                                                item-text="subject_title"
                                                item-value="subject_title"
                                                @change="updateSubjectChoice(1, item)"
                                                :disabled="item.disabled" 
                                                clearable                                                
                                            >
                                                <template v-slot:append-outer>
                                                    <v-fade-transition>
                                                        <v-progress-circular
                                                            v-if="item.saveStatus.saving"
                                                            size="16"
                                                            width="3"
                                                            color="info"
                                                            indeterminate
                                                        ></v-progress-circular>
                                                    </v-fade-transition>

                                                    <v-fade-transition>    
                                                        <v-icon
                                                            v-if="item.saveStatus.saveSuccess"
                                                            small
                                                            color="info"                                        
                                                        >
                                                            mdi-check-all
                                                        </v-icon>
                                                    </v-fade-transition>

                                                    <v-fade-transition>    
                                                        <v-icon
                                                            v-if="item.saveStatus.saveError"
                                                            small
                                                            color="red"                                        
                                                        >
                                                            mdi-alert-circle
                                                        </v-icon>
                                                    </v-fade-transition>
                                                </template>
                                            </v-select>
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>

                            <v-alert
                                text
                                color="primary"
                                dense
                                border="left"
                                colored-border
                                class="mt-4"
                            >
                                OPTION 2
                            </v-alert>

                            <v-data-table
                                :headers="headersSubjectChoice2"
                                :items="subjectChoices2"
                                hide-default-footer
                                hide-default-header
                            >
                                <template v-slot:item="{ item }">
                                    <tr>
                                        <td>
                                            {{ item.line }}
                                        </td>
                                        <td>
                                            <v-select
                                                v-model="subjectsChoice2[item.line]"
                                                outlined
                                                dense
                                                hide-details
                                                placeholder="Subject"
                                                :items="item.subjects" 
                                                item-text="subject_title"
                                                item-value="subject_title"
                                                @change="updateSubjectChoice(2, item)"
                                                :disabled="item.disabled" 
                                                clearable                                             
                                            >
                                                <template v-slot:append-outer>
                                                    <v-fade-transition>
                                                        <v-progress-circular
                                                            v-if="item.saveStatus.saving"
                                                            size="16"
                                                            width="3"
                                                            color="info"
                                                            indeterminate
                                                        ></v-progress-circular>
                                                    </v-fade-transition>

                                                    <v-fade-transition>    
                                                        <v-icon
                                                            v-if="item.saveStatus.saveSuccess"
                                                            small
                                                            color="info"                                        
                                                        >
                                                            mdi-check-all
                                                        </v-icon>
                                                    </v-fade-transition>

                                                    <v-fade-transition>    
                                                        <v-icon
                                                            v-if="item.saveStatus.saveError"
                                                            small
                                                            color="red"                                        
                                                        >
                                                            mdi-alert-circle
                                                        </v-icon>
                                                    </v-fade-transition>
                                                </template>
                                            </v-select>
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-card-text>
                        
                    </v-card>
                </v-col>
            </v-row>

            <v-row class="mt-4">
                <v-col
                    align="center"
                    justify="center"
                >
                    <v-card            
                        width="550"
                    >  
                        <v-card-title class="secondary--text pl-4 primary">
                            <div>DOCUMENTS UPLOAD</div> 
                            
                        </v-card-title>
                        <v-card-text>
                            <v-alert
                                border="left"
                                colored-border
                                type="info"
                                class="mt-4 text-justify"
                                color="primary"
                                text
                                outlined
                            >
                                Please upload scanned copies of the required documents. Documents must be in <strong>PDF</strong> format.
                                The passport photo must be either <strong>JPEG</strong> or <strong>PNG</strong> format.
                            </v-alert>
                            
                        </v-card-text>                      
                        
                    </v-card>
                </v-col>
            </v-row>

            <v-row 
                class="mt-4"
                v-for="document in documents"
                :key="document.label"
            >
                <v-col
                    align="center"
                    justify="center"
                >
                    <upload-file
                        v-bind:fileLabel="document.label"
                        v-bind:fileType="document.type"
                        v-bind:disabled="document.disabled"
                    ></upload-file>
                </v-col>
            </v-row>

            <v-row class="mt-4">
                 <v-col
                    align="center"
                    justify="center"
                >
                    <v-card            
                        width="550"
                    >  
                        <v-btn
                            block
                            color="primary"
                            @click="displayApplicationForm"
                        >
                            Generate Application Form
                        </v-btn>
                    </v-card>
                </v-col>
            </v-row>       
        </v-main>

        <v-overlay
            :value="overlay"
            opacity="0.8"
            z-index="10"                    
        >
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>                   
        </v-overlay>

        <v-dialog
            v-model="dialogReport"                      
            persistent
        >
           <v-card height="80vh">
            <iframe ref="pdf" style="width:100%; height:100%" frameBorder="0" :src="src"></iframe>
           </v-card>

            <v-container
                fluid
                class="pa-0"
            >
                <v-sheet>
                    <v-row
                        justify="end"
                        class="ma-0"
                    >

                        <v-btn
                            color="primary"
                            dark
                            @click="closeRegistrationForm"
                            class="my-2 mr-4"
                        >
                            Close
                        </v-btn>

                    </v-row>
                </v-sheet>
            </v-container>

        </v-dialog>

        <v-dialog
            v-model="dialogError"
            width="500"
        >
            <v-card>
                <v-card-title
                    class="text-h5 red lighten-2 "
                    
                >
                    <v-icon  left>
                        mdi-alert-circle                        
                    </v-icon>
                    Application Error
                </v-card-title>
                <v-card-text class="text-h6">
                    {{ errorMessage }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="dialogError = false"
                    >
                        OK
                    </v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>
    </v-app>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex';
import DatePicker from '../components/DatePicker.vue';
import UploadFile from '../components/UploadFiles.vue';
export default {
    created: function () {
        this.initialize();
    },

    components: {
        DatePicker,
        UploadFile,
    },

    data: function () {
        return {
            fields: [
                {
                    label: 'First Name', 
                    model: 'first_name', 
                    type: 'text', 
                    disabled: false,
                    filled: false,
                    required: true,
                    ref: 'firstName',
                    saveStatus: {
                        saving: false,
                        saveSuccess: false,
                        saveError: false,
                    }, 
                    record: 'application',
                    error: false,
                    errorMessages: '',
                },
                {
                    label: 'Last Name', 
                    model: 'last_name', 
                    type: 'text', 
                    disabled: false,
                    filled: false,
                    required: true,
                    ref: 'last_name',
                    saveStatus: {
                        saving: false,
                        saveSuccess: false,
                        saveError: false,
                    },                    
                    record: 'application',
                    error: false,
                    errorMessages: '',
                },
                {
                    label: 'Address', 
                    model: 'address', 
                    type: 'textArea', 
                    disabled: false,
                    filled: false,
                    required: false,
                    ref: 'address',
                    saveStatus: {
                        saving: false,
                        saveSuccess: false,
                        saveError: false,
                    }, 
                    record: 'application',
                    error: false,
                    errorMessages: '',
                },
                {
                    label: 'Birth Certificate Pin', 
                    model: 'birth_certificate_pin', 
                    type: 'text', 
                    disabled: false,
                    filled: false,
                    required: true,
                    ref: 'birth_certificate_pin',
                    saveStatus: {
                        saving: false,
                        saveSuccess: false,
                        saveError: false,
                    }, 
                    record: 'application',
                    error: false,
                    errorMessages: '',
                },
                {
                    label: 'Date of Birth', 
                    model: 'date_of_birth', 
                    type: 'date', 
                    disabled: false,
                    filled: false,
                    required: false,
                    ref: 'date_of_birth',
                    saveStatus: {
                        saving: false,
                        saveSuccess: false,
                        saveError: false,
                    }, 
                    record: 'application',
                    error: false,
                    errorMessages: '',
                },
                {
                    label: 'Previous School', 
                    model: 'previous_school', 
                    type: 'text', 
                    disabled: false,
                    filled: false,
                    required: false,
                    ref: 'previous_school',
                    saveStatus: {
                        saving: false,
                        saveSuccess: false,
                        saveError: false,
                    }, 
                    record: 'application',
                    error: false,
                    errorMessages: '',
                },
                {
                    label: 'Email (Student)', 
                    model: 'email', 
                    type: 'email', 
                    disabled: false,
                    filled: false,
                    required: true,
                    ref: 'email',
                    saveStatus: {
                        saving: false,
                        saveSuccess: false,
                        saveError: false,
                    }, 
                    record: 'application',
                    error: false,
                    errorMessages: '',
                    maxLength: 100,
                },
                {
                    label: 'Telephone Contact Student (Home)', 
                    model: 'phone_home', 
                    type: 'phone', 
                    disabled: false,
                    filled: false,
                    required: false,
                    ref: 'phone_home',
                    saveStatus: {
                        saving: false,
                        saveSuccess: false,
                        saveError: false,
                    }, 
                    record: 'application',
                    error: false,
                    errorMessages: '',
                },
                {
                    label: 'Telephone Contact Student (Mobile)', 
                    model: 'phone_mobile', 
                    type: 'phone', 
                    disabled: false,
                    filled: false,
                    required: false,
                    ref: 'phone_mobile',
                    saveStatus: {
                        saving: false,
                        saveSuccess: false,
                        saveError: false,
                    }, 
                    record: 'application',
                    error: false,
                    errorMessages: '',
                },
                {
                    label: 'Parent / Guardian Name', 
                    model: 'parent_name', 
                    type: 'text', 
                    disabled: false,
                    filled: false,
                    required: false,
                    ref: 'parent_name',
                    saveStatus: {
                        saving: false,
                        saveSuccess: false,
                        saveError: false,
                    }, 
                    record: 'application',
                    error: false,
                    errorMessages: '',
                },
                {
                    label: 'Telephone Contact Parent (Home)', 
                    model: 'phone_home_parent', 
                    type: 'phone', 
                    disabled: false,
                    filled: false,
                    required: false,
                    ref: 'phone_home_parent',
                    saveStatus: {
                        saving: false,
                        saveSuccess: false,
                        saveError: false,
                    }, 
                    record: 'application',
                    error: false,
                    errorMessages: '',
                },
                {
                    label: 'Telephone Contact Parent (Mobile)', 
                    model: 'phone_mobile_parent', 
                    type: 'phone', 
                    disabled: false,
                    filled: false,
                    required: true,
                    ref: 'phone_mobile_parent',
                    saveStatus: {
                        saving: false,
                        saveSuccess: false,
                        saveError: false,
                    }, 
                    record: 'application',
                    error: false,
                    errorMessages: '',
                },
                {
                    label: 'Email (Parent)', 
                    model: 'email_parent', 
                    type: 'email', 
                    disabled: false,
                    filled: false,
                    required: false,
                    ref: 'email_parent',
                    saveStatus: {
                        saving: false,
                        saveSuccess: false,
                        saveError: false,
                    }, 
                    record: 'application',
                    error: false,
                    errorMessages: '',
                    maxLength: 100,
                },
            ],
            recordApplication: {},
            recordApplicationGrades: {},
            recordApplicationSubjectChoices: {},
            headers: [
                { text: 'SUBJECTS', subtext: ' ', value: 'subject'},
                { text: 'GRADE', subtext: '(I,II,III)', value: 'grade'},
                { text: 'PROFILE', subtext: '(e.g. A,B,A)', value: 'profile'},
                { text: '', subtext: ''},
            ],
            subjects: [
                { 
                    id: '', 
                    grade: '', 
                    profiles: '',
                    saveStatus: {
                        saving: false,
                        saveSuccess: false,
                        saveError: false,
                    },
                    disabled: false,  
                },
                { 
                    id: '', 
                    grade: '', 
                    profiles: '',
                    saveStatus: {
                        saving: false,
                        saveSuccess: false,
                        saveError: false,
                    },
                    disabled: false,    
                },
                { 
                    id: '', 
                    grade: '', 
                    profiles: '',
                    saveStatus: {
                        saving: false,
                        saveSuccess: false,
                        saveError: false,
                    },
                    disabled: false,    
                },
                { 
                    id: '', 
                    grade: '', 
                    profiles: '',
                    saveStatus: {
                        saving: false,
                        saveSuccess: false,
                        saveError: false,
                    },
                    disabled: false,    
                },
                { 
                    id: '', 
                    grade: '', 
                    profiles: '',
                    saveStatus: {
                        saving: false,
                        saveSuccess: false,
                        saveError: false,
                    },
                    disabled: false,    
                },
                { 
                    id: '', 
                    grade: '', 
                    profiles: '',
                    saveStatus: {
                        saving: false,
                        saveSuccess: false,
                        saveError: false,
                    },
                    disabled: false,    
                },
                { 
                    id: '', 
                    grade: '', 
                    profiles: '',
                    saveStatus: {
                        saving: false,
                        saveSuccess: false,
                        saveError: false,
                    }, 
                    disabled: false,   
                },
                { 
                    id: '', 
                    grade: '', 
                    profiles: '',
                    saveStatus: {
                        saving: false,
                        saveSuccess: false,
                        saveError: false,
                    },
                    disabled: false,    
                },
                { 
                    id: '', 
                    grade: '', 
                    profiles: '',
                    saveStatus: {
                        saving: false,
                        saveSuccess: false,
                        saveError: false,
                    },
                    disabled: false,    
                },
                { 
                    id: '', 
                    grade: '', 
                    profiles: '',
                    saveStatus: {
                        saving: false,
                        saveSuccess: false,
                        saveError: false,
                    },
                    disabled: false,    
                },
                
               
            ],
            csecSubjects: [],
            grades: [
                'I','II','III','IV','V','VI'
            ],
            headersSubjectChoice1: [
                {text: ''},               
                {text: 'OPTION 1'},               
            ],
            headersSubjectChoice2: [
                {text: ''}, 
                {text: 'OPTION 2'},               
            ],
            subjectChoices1:[
                {
                    line: 1, 
                    subjects: [],
                    saveStatus: {
                        saving: false,
                        saveSuccess: false,
                        saveError: false,
                    }, 
                    disabled: false,
                },             
                {
                    line: 2, 
                    subjects: [],
                    saveStatus: {
                        saving: false,
                        saveSuccess: false,
                        saveError: false,
                    },
                    disabled: false, 
                },          
                {
                    line: 3, 
                    subjects: [],
                    saveStatus: {
                        saving: false,
                        saveSuccess: false,
                        saveError: false,
                    },
                    disabled: false, 
                },            
                 {
                    line: 4, 
                    subjects: [],
                    saveStatus: {
                        saving: false,
                        saveSuccess: false,
                        saveError: false,
                    },
                    disabled: false, 
                },            
            ],
            subjectChoices2:[
                {
                    line: 1, 
                    subjects: [],
                    saveStatus: {
                        saving: false,
                        saveSuccess: false,
                        saveError: false,
                    },
                    disabled: false, 
                },             
                {
                    line: 2, 
                    subjects: [],
                    saveStatus: {
                        saving: false,
                        saveSuccess: false,
                        saveError: false,
                    },
                    disabled: false, 
                },          
                 {
                    line: 3, 
                    subjects: [],
                    saveStatus: {
                        saving: false,
                        saveSuccess: false,
                        saveError: false,
                    },
                    disabled: false, 
                },            
                 {
                    line: 4, 
                    subjects: [],
                    saveStatus: {
                        saving: false,
                        saveSuccess: false,
                        saveError: false,
                    },
                    disabled: false, 
                },            
            ],
            overlay: false,
            subjectsChoice1:{},
            subjectsChoice2:{},
            src: '',
            dialogReport: false,
            documents: [
                {
                    label: 'Birth Certificate', 
                    placeholder: 'Upload your birth certificate', 
                    type: 'birth_certificate',
                    ref: 'birth_certificate',
                    disabled: false,
                },
                {
                    label: 'CSEC Results Slip', 
                    placeholder: 'Upload your CSEC results slip', 
                    type: 'results_slip',
                    ref: 'results_slip',
                    disabled: false,
                },
                {
                    label: 'Passport Photo', 
                    placeholder: 'Upload Passport Photo', 
                    type: 'picture',
                    ref: 'picture',
                    disabled: false,
                },
                {
                    label: 'Transfer Form', 
                    placeholder: 'Upload Transfer Form', 
                    type: 'transfer_form',
                    ref: 'transferForm',
                    disabled: false,
                },
                {
                    label: 'Recommendation', 
                    placeholder: 'Upload Recommendation', 
                    type: 'recommendation_1',
                    ref: 'recommendation1',
                    disabled: false,
                },
            ],
            formHasErrors: false,
            errorMessage: '',
            dialogError: false,
        }
    },

    computed: {
        ...mapGetters({
            application: 'application/getApplication',
            applicationGrade: 'application/getApplicationGrade',
        }),

        regex(){
            return new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) 
        },
    },

    methods: {
        async initialize () {
            this.overlay = true;
            
            this.recordApplication = JSON.parse(sessionStorage.getItem('application'));
            // this.recordApplication = JSON.parse(this.application);
            this.setApplication(this.recordApplication);
            try {
                const promiseApplication = this.getApplication();
                const promiseSubjectLines = this.getSubjectLines();
                const promiseApplicationSubjectChoices = this.getApplicationSubjectChoices();
                const promiseCsecSubjects = this.getCsecSubjects();
                const promiseApplicationSubjectGrades = this.getApplicationSubjectGrades();
                const promiseApplicationsLockStatus = this.getApplicationsLockStatus();

                const[
                    { data: dataApplication},
                    { data: dataSubjectLines},
                    { data: dataSubjectChoices },
                    { data: dataCsecSubjects },
                    { data: dataSubjectGrades },
                    { data: dataApplicationsLockStatus }
                ] = await Promise.all([
                    promiseApplication,
                    promiseSubjectLines,
                    promiseApplicationSubjectChoices,
                    promiseCsecSubjects,
                    promiseApplicationSubjectGrades,
                    promiseApplicationsLockStatus
                ])
                
                this.recordApplication = dataApplication;
                this.mapSubjectLines(dataSubjectLines);
                this.mapSubjectChoices(dataSubjectChoices);
                this.csecSubjects = dataCsecSubjects;
                this.mapCsecGrades(dataSubjectGrades);
                this.mapApplicationLock(dataApplicationsLockStatus);
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
            this.overlay = false;
        },

        ...mapMutations({
            setApplication: 'application/setApplication',
            setApplicationSubjectChoice: 'application/setApplicationSubjectChoice',
            setApplicationGrade: 'application/setApplicationGrade',
        }),

        ...mapActions({
            postApplication: 'application/postApplication',
            postApplicationGrade: 'application/postApplicationGrade',
            postApplicationSubjectChoice: 'application/postApplicationSubjectChoice',
            getApplication: 'application/getApplication',
            getSubjectLines: 'application/getSubjectLines',
            getApplicationSubjectChoices: 'application/getApplicationSubjectChoices',
            getCsecSubjects: 'application/getCsecSubjects',
            getApplicationSubjectGrades: 'application/getApplicationSubjectGrades',
            getApplicationsLockStatus: 'application/getApplicationsLockStatus',
        }),

        mapSubjectLines (data) {
            this.subjectChoices1.forEach(choice => {
                switch (choice.line){
                    case 1:
                        choice.subjects = data[1];
                        break;
                    case 2:
                        choice.subjects = data[2];
                        break;
                    case 3:
                        choice.subjects = data[3];
                        break;
                    case 4:
                        choice.subjects = data[4];
                        break;
                }
            });

            this.subjectChoices2.forEach(choice => {
                switch (choice.line){
                    case 1:
                        choice.subjects = data[1];
                        break;
                    case 2:
                        choice.subjects = data[2];
                        break;
                    case 3:
                        choice.subjects = data[3];
                        break;
                    case 4:
                        choice.subjects = data[4];
                        break;
                }
            });
        },

        mapSubjectChoices (data) {
            data.forEach(value => {
                if(value.choice == 1){
                    this.subjectsChoice1[value.line] = value.subject_title;
                }
                else if(value.choice == 2){
                    this.subjectsChoice2[value.line] = value.subject_title;
                }
            })
        },

        mapApplicationLock ({ locked }) {
            console.log(locked);
            if(locked){
                this.fields.forEach(f => {
                    f.disabled = true;
                });
                this.subjects.forEach(s => {
                    s.disabled = true;
                });
                this.subjectChoices1.forEach(c => {
                    c.disabled = true;
                })
                this.subjectChoices2.forEach(c => {
                    c.disabled = true;
                })
                this.documents.forEach(d => {
                    d.disabled = true;
                })
            }
        },

        mapCsecGrades (data) {
            data.forEach((value,index) => {
                this.subjects[index].id = value.subject_id;
                this.subjects[index].grade = value.grade;
                this.subjects[index].profiles = value.profiles
            })
        },

        validateForm () {
            this.formHasErrors = false;
            this.fields.forEach(f => {
                this.validate(f, 'application');
            })
            return !this.formHasErrors;
        },

        validate (field, record) {
            // console.log(field)
            if(
                field.type === 'email' && 
                this.recordApplication[field.model] && 
                !this.regex.test(this.recordApplication[field.model])
            ){
                // console.log("email invalid");
                field.error = true;
                field.errorMessages = "Invalid Email.";
                this.formHasErrors = true;
                return
            }
            else{
                field.error = false;
                field.errorMessages = '';
            }

            if(field.type === 'phone' && this.recordApplication[field.model] && this.recordApplication[field.model].length < 7){
                // console.log("phone number invalid");
                field.error = true;
                field.errorMessages = "7 Digits Needed.";
                this.formHasErrors = true;
                return
            }
            else{
                field.error = false;
                field.errorMessages = '';
            }

            if(field.required && !this.recordApplication[field.model]){
                // console.log("field missing");
                field.error = true;
                field.errorMessages = "Field Required."
                this.formHasErrors = true;
                return
            }
            else{
                field.error = false;
                field.errorMessages = '';
            }

            switch (record) {
                case 'application':
                    if(this.recordApplication[field.model]){
                        this.update(record, field)
                    }
            }
        },

        update (record, field) {
            switch (record) {
                case 'application':
                    this.updateApplication(field);
                    break;

            }    
        },

        async updateApplication (field) {
            this.setSaveStatus(field);
            try {
                this.setApplication(this.recordApplication);
                const { status }= await this.postApplication();
                console.log(status);
                this.setSaveStatus(field, 'saveSuccess');
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.setSaveStatus(field, 'saveError');  
            }
        },

        updateSubjectChoice (choice, field) {
            // console.log(field);
            this.setSaveStatusSubjectChoice (choice, field.line, 'saving');
            let subjectChoice = {};
            if(choice == 1 ){
                subjectChoice = {
                    application_id: this.recordApplication.application_id,
                    year: this.recordApplication.year,
                    subject_title: this.subjectsChoice1[field.line],
                    line: field.line,
                    choice: 1
                }
            }
            if(choice == 2 ) {
                 subjectChoice = {
                    application_id: this.recordApplication.application_id,
                    year: this.recordApplication.year,
                    subject_title: this.subjectsChoice2[field.line],
                    line: field.line,
                    choice: 2
                }
            }
            this.setApplicationSubjectChoice(subjectChoice);
            console.log(subjectChoice);
            this.saveSubjectChoice(choice, field.line,);
        },

        async saveSubjectChoice (choice, line,) {
            try {
                const response = await this.postApplicationSubjectChoice();
                console.log(response);
                this.setSaveStatusSubjectChoice (choice, line, 'saveSuccess');
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.setSaveStatusSubjectChoice (choice, line, 'saveError');
            }
        },

        updateDate (date) {
            console.log(date);
            this.recordApplication.date_of_birth = date;
            let dateOfBirthField = this.fields.filter(field => field.model == 'date_of_birth')[0]
            this.update('application', dateOfBirthField);
        },

        updateCsecGrade (index) {
            if(
                this.subjects[index].id &&
                this.subjects[index].grade &&
                this.subjects[index].profiles
            ){
                let subjectId = this.subjects[index].id;
                let grade = this.subjects[index].grade;
                let profiles = this.subjects[index].profiles;
                this.setApplicationGrade({
                    application_id: this.recordApplication.application_id,
                    year: this.recordApplication.year,
                    subject_id: subjectId,
                    grade: grade,
                    profiles: profiles,
                    examination_year: null
                });
                this.saveCsecGrade(index);
            }
        },

        async saveCsecGrade (index) {
            console.log('saving grade record');
            console.log(this.applicationGrade);
            this.setSaveStatusSubjectGrade (index, 'saving')
            try {
                const response = await this.postApplicationGrade();
                console.log(response)
                this.setSaveStatusSubjectGrade (index, 'saveSuccess')
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.setSaveStatusSubjectGrade (index, 'saveError')
            }
        },

        setSaveStatus (field, status = 'saving') {
            this.fields.forEach(value => {
                if(value.model == field.model){
                    Object.keys(field.saveStatus).forEach(key => {
                        if(key == status) field.saveStatus[key] = true;
                        else field.saveStatus[key] = false;
                    })
                }
            })
        },

        setSaveStatusSubjectChoice (choice, line, status) {
            console.log('save subject choice');
            if(choice == 1){
                this.subjectChoices1.forEach(value => {
                    if(value.line == line){
                        Object.keys(value.saveStatus).forEach(key => {
                            value.saveStatus[key] = false;
                            if(key == status){
                                value.saveStatus[key] = true;
                            }
                        })
                    }
                })
            }
            else if(choice == 2){
                this.subjectChoices2.forEach(value => {
                    if(value.line == line){
                        Object.keys(value.saveStatus).forEach(key => {
                            value.saveStatus[key] = false;
                            if(key == status){
                                value.saveStatus[key] = true;
                            }
                        })
                    }
                })
            }
        },

        setSaveStatusSubjectGrade (index, status) {
            Object.keys(this.subjects[index].saveStatus).forEach(key => {
                this.subjects[index].saveStatus[key] = false;
                if(key == status){
                    this.subjects[index].saveStatus[key] = true;
                }
            })
        },

        numberOnly($event){            
            if(!/\d/.test($event.key)) return $event.preventDefault();
        },

        displayApplicationForm () {
            this.src = process.env.VUE_APP_API_URI + "/api/sixth-form-application-form/" + 
            this.recordApplication.application_id + "/" +
            this.recordApplication.year;
            // console.log(`Form Validation ${this.validateForm()}`);
            if(!this.validateForm()){
                this.errorMessage = "Your application is incomplete please review and complete the missing fields.";
                this.dialogError = true;
                this.src = '';
                return;
            }
            this.dialogReport = true;
            
        },

        closeRegistrationForm () {
            this.dialogReport = false;
            this.src = '';
        },

        showInstructions () {
            this.src = process.env.VUE_APP_API_URI + "/api/sixth-form-application-instructions";
            this.dialogReport = true;
        },

        closeApplication () {
            this.$router.replace('/');
        }
    }
   
}
</script>

<style scoped>
    ::v-deep .theme--light.v-btn.v-btn--outlined.v-btn--text{
        position: fixed;
        z-index: 2;
    }

    ::v-deep .v-btn--fab.v-size--small.v-btn--absolute.v-btn--top {
        top: 25px;
    }
</style>
