<template>
    <v-dialog
        ref="dialog"
        v-model="modal"
        :return-value.sync="dateModel"
        persistent
        width="290px"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="dateModel"
                readonly
                v-bind="attrs"
                v-on="on"
                hide-details
                :label="label"
                :disabled="disabled"
            >
                <template v-slot:append>
                    <v-fade-transition>
                        <v-progress-circular
                            v-if="saveStatus.saving"
                            size="16"
                            width="3"
                            color="info"
                            indeterminate
                        ></v-progress-circular>
                    </v-fade-transition>

                    <v-fade-transition>    
                        <v-icon
                            v-if="saveStatus.saveSuccess"
                            small
                            color="info"                                        
                        >
                            mdi-check-all
                        </v-icon>
                    </v-fade-transition>

                    <v-fade-transition>    
                        <v-icon
                            v-if="saveStatus.saveError"
                            small
                            color="red"                                        
                        >
                            mdi-alert-circle
                        </v-icon>
                    </v-fade-transition>
                </template>
            </v-text-field>
        </template>
        <v-date-picker
            v-model="dateModel"
            scrollable
        >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="modal = false"
            >
                Cancel
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="saveDate"
            >
                OK
            </v-btn>
        </v-date-picker>
    </v-dialog>
</template>

<script>
export default {
    props: {
        date: {
            type: String,
            default: null,
        },
        
        label: {
            type: String,
            default: null,
        },
        saveStatus: Object,
        disabled: Boolean,        
    },   

    watch: {
        date: {
            handler (val) {
                // console.log(`Date: ${val}`)
                this.dateModel = val;
            }
        },
      
    },

    data: function () {
        return {       
            modal: false,
            dateModel: this.date,
        }
    },

    methods: {
        saveDate () {
            this.$refs.dialog.save(this.dateModel);
            this.$emit('change-date', this.dateModel);            
        }
    }
}
</script>